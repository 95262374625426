import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/home/home_two'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  // {
  //   path: '/noPermissions',
  //   name: 'noPermissions',
  //   component: () => import('@/views/noPermissions/noPermissions'),
  // },
  {
    path: '/home_two',
    name: 'home_two',
    component: () => import('@/views/home/home_two'),
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/home/download'),
  },
  {
    path: '/mobile_down',
    name: 'mobile_down',
    component: () => import('@/views/home/mobile_down'),
  },
  
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
