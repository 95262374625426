import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import Jp from '../lang/jp.js'; // 日语语言包
import En from '../lang/en.js'; // 英文语言包
import Py from '../lang/py.js'; // 俄语语言包
import Po from '../lang/po.js'; // 西班牙语言包
import In from '../lang/in.js'; // 印度尼西亚语言包



const messages = {
    zh: Zh, 
    jp: Jp, 
    en: En, 
    py: Py, 
    po: Po, 
    in: In, 
}
window.localStorage.setItem("lang", 'en')
// window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});