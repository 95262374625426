export default {
    home: {
        language: " Переключение языка ",
        banner_p1: "Начни свое ",
        banner_p2: "путешествие в мире криптовалюты",
        banner_msg1: " Безопасность ",
        banner_msg2: "Стабильность ",
        banner_msg3: " Эффективность ",
        banner_link1: "Зарегистрируйтесь и начните торговлю на платформе прямо сейчас",
        banner_link2: "Зарегистрируйтесь",
        swiper_h1: "Высокая производительность ",
        swiper_h2: "Соедините двигатели. ",
        swiper_h3: "Финансовый уровень ",
        swiper_h4: "Безопасность ",
        swiper_h5: " Продукты ",
        swiper_h6: "Богатство и разнообразие ",
        swiper_h7: "Глобализация ",
        swiper_h8: "Раскладка ",
    },
    list: {
        title: "Топ",
        th1: "Спот",
        th2: " Последняя Цена",
        th3: "Изменение 24ч,",
        th4: "Рынки",
        th5: "Торговля",
    },
    info1: {
        tit1: "Основные Функции Платформы",
        tit2: "Бессрочные Контракты Спот",
        p1: "Сверхмощный механизм сопоставления третьего поколения",
        p2: "Мощная система третьего поколения гарантирует, что все операции пользователей будут выполнены за 10-ю долю секунды,",
        p3: "Независимые исследования и разработки уникальных торговых функции",
        p4: "Скоростная торговля K-Line, Многоточечный функционал TP/SL, Трейлинг-Стоп, Режим объединения и Разделения позиции и другие уникальные функции помогут трейдерам торговать быстрее и эффективнее,",
        p5: "Максимально гибкие возможности для торговли",
        p6: "ICESAS предоставляет различные варианты левериджа, для трейдоров всех уровней,",
        btn: "Начать торговлю на бирже!"
    },
    info2: {
        tit1: "Функция транзакции один",
        tit2: "Скоростная Торговля K-Line",
        p1: "ыставление ордера одним нажатием",
        p2: "Скоростная торговля K-Line — это набор инновационных функциональных возможностей, эксклюзивно выпущенных только на ICESAS, Основываясь на мощной торговой функции K-Line, вы можете очень интуитивно и эффективно выполнять различные торговые операции с ордерами на K-Line графике,",
        p3: "Комплексное управление торговлей",
        p4: "Благодря K-Line функции, больше не нужно переходить с одной страницы на другую для выставления ордера, При стремительном и неожиданном изменении рынка, вы можете быстро разместить (изменить) ордер или внести корректировки в любое время, прямо на графике!",
    },
    info3: {
        tit1: "Функция транзакции два",
        tit2: "Режим разделения и объединения позиции",
        p1: "Свободное изменение нескольких позиции одновременно",
        p2: "Пользователи могут одновременно удерживать Лонг и Шорт позиции в одной и той же валюте, одновременно удерживая нескольких суб-позиций с разным уровнем левериджа, чтобы помочь трейдерам разработать гибкие торговые стратегии,",
        p3: "Низкий риск для независимых позиции",
        p4: "На нашей платформе, трейдеры могут держать несколько позиции в одной и той же валюте, с разным уровнем левериджа, В каждой суб-позиции можно независимо регулировать уровень левериджа, увеличивать или уменьшать позиции, устанавливать TP и SL, а также объединять суб-позиции без каких либо потерь,",
    },
    info4: {
        tit1: "Функция транзакции три",
        tit2: "Обширные инструменты TP и SL",
        p1: "Многоточечный функционал TP/SL",
        p2: "Пользователи ICESAS могут воплощать свои различные торговые стратегии в любое время установливая TP и SL на уже выложенные ордера, для гибкого управления рисками, при этом поддерживается установка до 50 TP и SL на одну и ту же позицию, но с разными cont и ценой, включая лимитные и рыночные ордера,",
        p3: "До 50 различных уровней TP/SL",
        p4: "ICESAS обладает мощной функциональностью TP/SL, которая позволяет вам закрывать сделки в нескольких ценовых точках в рамках одной позиции, Если вы не уверены, в каком направлении пойдет рынок, вы можете контролировать риски, закрыв половину своей позиции с прибылью и оставив другую половину открытой, Защитите свои активы от колебаний рынка криптовалют и принудительной ликвидации с помощью этой замечательной функции,",
    },
    info5: {
        p1: "Более трех лет стабильной работы Без сбоев и застревании",
        p2: "Публикации на известных платформах в СМИ",
        p3: "KOL сотрудничаютс нами",
        p4: "Пользователей выбирают нашу платформу",
    },
    why: {
        p1: "Почему стоит выбрать именно ICESAS?",
        p2: "Независимые Исследования и Разработки",
        p3: "Система третьего поколения Сверхэффективная торговля с отличной",
        p4: "производительностью",
        p5: "Высокий Уровень Безопасности",
        p6: "Контроль рисков Горячего и Холодного кошельков",
        p7: "Двойная гарантия защиты ваших активов",
        p8: "Безупрченая Система Контроля Рисков",
        p9: "Механизм защиты от двойной ликвидации",
        p10: "Пониженный риск ликвидации",
        p11: "Профессиональная Команда",
        p12: "Эксперты в своем деле  Наша команда",
        p13: "распределена по всему миру",
        p14: "Многоязычная Служба Поддержки 24/7",
        p15: "Быстрая и эффективная служба поддержки",
        p16: "Круглосуточная поддержка клиентов для пользователей по всему миру",
    },
    info6: {
        p1: "Начнем торговлю криптовалютой сейчас?",
        p2: "Начните инвестировать в криптовалюту прямо сейчас",
    },
    foot: {
        download: "скачать"
    },
    download: {
        h1: "Пожалуйста, используйте ваш мобильный телефон для сканирования QR-кода для загрузки",
        copy: "копия",
        down: "скачать",
        rate: "рейтинг",
        age: "возраст",
        complain: "приложение",
        tit1: "Новые возможности",
        version: "Версия", 
        p1: "В этой версии исправлены ошибки и улучшена производительность",
        tit2: "Представление приложения",
        p2: "Добро пожаловать на ICESAS Global, одну из ведущих мировых бирж цифровых активов! Торгуйте более чем 1100 криптовалютами, включая Bitcoin (BTC), Ether (ETH), Dogcoin (DOGE), MX Token (MX) и другие, Как ведущая мировая криптовалютная биржа, ICESAS известна тем, что первой запускает высококачественные проекты и модальные монеты, ICESAS Global предоставляет профессиональные, безопасные услуги по торговле и управлению цифровыми активами, которым доверяют более 7 миллионов пользователей по всему миру," ,
        tit3: "Отзывы и рейтинги",
        p3: "из 5", 
        tit4: "Информация",
        label1: "Продавцы",
        label2: "Размер",
        label3: "Совместимость",
        label4: "Языки",
        value1: "Китайский и английский языки",
        label5: "возраст (человека)",
        value2: "Только старше 18 лет",
        label6: "Цена",
        value3: "свободно",
        tit5: "Политика конфиденциальности",
        p5: "Отказ от ответственности: на этом сайте только загружается и устанавливается приложение для разработчика, за содержание и работу, связанную с приложением, отвечает разработчик приложения и не имеет никакого отношения к данному сайту,"
    }
};
