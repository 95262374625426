export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        case "zh": //中文
            lang = "zh";
            break;
        case "jp": //日语
            lang = "jp";
            break;
        case "en": //英文
            lang = "en";
            break;
        case "py": //
            lang = "py";
            break;
        case "po": //
            lang = "po";
            break;
        case "in": //
            lang = "in";
            break;



        default:
            lang = "en";
    }
    return lang;
}