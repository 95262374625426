export default {
    home: {
        language: "言語切り替え",
        banner_p1: "暗号資産",
        banner_p2: "投資の旅をはじめる",
        banner_msg1: "安全",
        banner_msg2: "安定",
        banner_msg3: "高効率",
        banner_link1: "早速CoinStoreのアカウントを作成しよう",
        banner_link2: "早速CoinStoreの",
        swiper_h1: "高性能",
        swiper_h2: "つまみエンジン",
        swiper_h3: "金融レベル",
        swiper_h4: "あんぜんほご",
        swiper_h5: "製品",
        swiper_h6: "バラエティーに富んでいる",
        swiper_h7: "グローバル化",
        swiper_h8: "レイアウト",
    },
    list: {
        title: "人気",
        th1: "現物",
        th2: "現在価格",
        th3: "24時間変動",
        th4: "マーケット",
        th5: "取引",
    },
    info1: {
        tit1: "多様多彩なサービスをあなたに届く",
        tit2: "無期限契約 現物",
        p1: "第三世代マッチングエンジン",
        p2: "強力のマッチングエンジンの採用で,ユーザーのすべてのコア操作は1秒以内で完成できることを確保します",
        p3: "独自開発された個性的な取引機能",
        p4: "チャート取引,ポジション分割機能,利食い損切り設定など6つの独創的な取引機能でトレーダーに完璧な取引体験を届きます",
        p5: "柔軟性を持つトレード戦略",
        p6: "最大125倍までマルチレバレッジを選択できるし,初心者からプロまであらゆるトレーダーの好みを満足できます",
        btn: "取引へ"
    },
    info2: {
        tit1: "取引機能その一",
        tit2: "ワンクリックチャート取引",
        p1: "0.1秒でチャートから発注",
        p2: "チャート取引はCoinStoreからご提供した革新的な契約取引ツールです。相場が激しく変動されるときも慌てずにベスト価格をチャプチャー可能です",
        p3: "全面的に取引管理とリスクマネジメント",
        p4: "高性能なチャートを基づいて,非常に直感的で高効率にチャートにて注文の発注,未約定注文の変更,ポジション決済と利食い損切り設定などの操作ができます",
    },
    info3: {
        tit1: "取引機能その二",
        tit2: "ポジション「分割」機能",
        p1: "自由にポジションモードを切り替え",
        p2: "同一取引ペアの同じ方向または違う方向で複数のポジションが作れます。この機能によってポジション管理の細分化が実現できます",
        p3: "独立したポジションでリスクを低める",
        p4: "「分割」ボジションはそれぞれ独立に存在して,各自のレバレッジ調整,追加注文と利食い損切り設定ができ,さらにポジション間の合併ができ,クロスマージンでポジションの損益が与えた影響を低めることが可能です",
    },
    info4: {
        tit1: "取引機能その三",
        tit2: "極めて柔軟性のある利食い損切り設定",
        p1: "多様な利食い損切り設定方法",
        p2: "注文を発注する際,または保持しているポジション上の利食い/損切りの設定が支持され,その上,全部/部分的,条件付き利食い損切りの設定ができます",
        p3: "最大50個まで同時に設定可能",
        p4: "同じポジションで,異なる数量と価格で,最大50個まで利食い損切りを同時に設定することに対応可能です。すべてのユーザーの取引ニーズを満足できます",
    },
    info5: {
        p1: "3年間安定運営ダウンタームなし",
        p2: "業界有力メディアに認定されたサービス",
        p3: "世界トップのインフルエンサーとのコラボレーション",
        p4: "百万以上のユーザーに愛用される",
    },
    why: {
        p1: "CoinStoreを選択する理由",
        p2: "磨かれた性能開発",
        p3: "初心者とプロでも優しい取引環境",
        p4: "高速で安全なマッチングエンジン",
        p5: "高度な安全対策",
        p6: "ホット＆コールドウォレットの2",
        p7: "重保護であなたの資産安全を確保",
        p8: "完全なリスクコントロールシステム",
        p9: "強力な強制決済メカニズム",
        p10: "取引リスクを最低限に控える",
        p11: "グローバル人材",
        p12: "国内外フィンテック専門家集まり",
        p13: "資産管理の長年経験持ち",
        p14: "日本語サポート",
        p15: "24時間年中無休",
        p16: "メールとチャットでいつでも対応",
    },
    info6: {
        p1: "デジタル通貨の取引を開始しますか?",
        p2: "暗号資産取引をはじめましょう！",
    },
    foot: {
        download: "ダウンロード"
    },
    download: {
        h1: "携帯電話でQRコードをスキャンしてダウンロードしてください",
        copy: "コピー",
        down: "ダウンロード",
        rate: "評価",
        age: "年齢",
        complain: "アプリの苦情",
        tit1: "新機能",
        version: "バージョン",
        p1: "このバージョンにはバグフィックスとパフォーマンスの改善が含まれています",
        tit2: "アプリ紹介",
        p2: "世界有数のデジタル資産取引所であるCoinstore Globalへようこそ！ ビットコイン(BTC),イーサ(ETH),ドッグコイン(DOGE),MXトークン(MX)など,1,100以上の暗号通貨を取引できます。 世界有数の暗号通貨取引所であるCoinStoreは,高品質のプロジェクトやモードコインをいち早く立ち上げたことで知られています。ICESAS Globalは,世界中の700万人以上のユーザーから信頼されるプロフェッショナルで安全なデジタル資産取引・管理サービスを提供しています。",
        tit3: "レビューと評価",
        p3: "5点満点中",
        tit4: "情報",
        label1: "ベンダー",
        label2: "サイズ",
        label3: "互換性",
        label4: "言語",
        value1: "中国語・英語",
        label5: "ねんれい",
        value2: "18歳以上のみ",
        label6: "価格",
        value3: "フリーウェア",
        tit5: "プライバシーポリシー",
        p5: "免責事項：このウェブサイトは開発者のためにアプリのダウンロードとインストールをホストしているだけであり,アプリ内のコンテンツや操作に関する事項はアプリ開発者の責任であり,このウェブサイトとは何の関係もありません。"
    }
};
