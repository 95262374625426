export default {
    home: {
        language: "语言切换",
        banner_p1: "开启你的",
        banner_p2: "加密货币投资",
        banner_msg1: "安全",
        banner_msg2: "稳定",
        banner_msg3: "高效",
        banner_link1: "立即注册并开始使用",
        banner_link2: "立即注册",
        swiper_h1: "高性能",
        swiper_h2: "撮合引擎",
        swiper_h3: "金融级",
        swiper_h4: "安全防护",
        swiper_h5: "产品",
        swiper_h6: "丰富多样",
        swiper_h7: "全球化",
        swiper_h8: "布局",
    },
    list: {
        title: "热门",
        th1: "现货",
        th2: "最新价",
        th3: "24H涨跌幅",
        th4: "行情",
        th5: "去交易",
    },
    info1: {
        tit1: "平台核心产品",
        tit2: "永续合约 现货",
        p1: "第三代撮合引擎",
        p2: "强大的撮合系统，保证用户所有核心操作100毫秒内完成",
        p3: "自主研发特色交易功能",
        p4: "K线下单、超级和仓、多元止盈止损、双价格强平、一键反转等特色交易功能，帮助交易者做出更好的决策",
        p5: "灵活的交易策略",
        p6: "提供多种杠杆选择，满足各级别交易者的风险偏好",
        btn: "开始交易"
    },
    info2: {
        tit1: "交易功能一",
        tit2: "K线下单",
        p1: "一键极速下单",
        p2: "K线交易是CoinStore全网独家推出的一整套创新功能体验，基于强大的K线交易功能，直接在K线页面进行交易操作，无需在行情页和下单页之间来回切换",
        p3: "全方位交易管理",
        p4: "用户可以非常直观高效的在k线上完成各种交易操作，包括：仓位操作、订单操作。让用户在行情快速变化的时候，可以快速下单，随时精准调整",
    },
    info3: {
        tit1: "交易功能二",
        tit2: "超级分仓",
        p1: "多种仓位自由切换",
        p2: "用户在同一币种下可同时持有多空两个方向的合仓仓位，又可以持有N个不同杠杆的多个分仓仓位，并支持自由合并切换，方便用户灵活制定交易策略",
        p3: "独立仓位降低风险",
        p4: "一币种可持有多个不同杠杆的仓位，每个分仓都能独立调整杠杆、加仓减仓、设置止盈止损，且分仓之间可随意无损合并，帮助用户降低来自整体仓位盈亏的影响",
    },
    info4: {
        tit1: "交易功能三",
        tit2: "多元止盈止损",
        p1: "三大止盈止损功能",
        p2: "CoinStore提供全部仓位止盈止损、部分仓位止盈止损、触发价止盈止损三种模式，帮助用户控制交易风险",
        p3: "最多50个不同的止盈止损点位",
        p4: "用户可以在仓位、挂单中随时设置止盈止损点位，灵活控制风险；同时支持针对同一仓位不同张数和价格设置多达50个止盈止损单，包括限价单或市价单，满足用户不同投资策略",
    },
    info5: {
        p1: "稳定运营3年 无宕机卡顿",
        p2: "行业媒体渠道支持",
        p3: "头部KOL合作",
        p4: "百万用户的选择",
    },
    why: {
        p1: "为什么选择Coinstore",
        p2: "强大自主研发能力",
        p3: "从0到1自主研发第三代交易系统",
        p4: "打造卓越性能下的极致交易体验",
        p5: "顶级安全保障",
        p6: "冷热钱包隔离风险控制",
        p7: "双重手段保障您的资产安全",
        p8: "完善的风控体系",
        p9: "双价格强平保护机制",
        p10: "降低用户爆仓风险",
        p11: "全球化布局",
        p12: "全球精英汇聚",
        p13: "团队分布全球",
        p14: "7*24小时服务",
        p15: "1分钟响应机制",
        p16: "全天候服务全球用户",
    },
    info6: {
        p1: "开始交易数字货币？",
        p2: "立刻开启你的加密货币投资",
    },
    foot: {
        download: "下载",
        copy_success: "复制成功",
        copy_error: "复制失败",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 ICESAS Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，CoinStore以率先推出优质项目和模因币而闻名, ICESAS Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
